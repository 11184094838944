import emailjs from '@emailjs/browser';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik } from 'formik';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import 'yup-phone';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { open } from '../../features/responseSlice/responseSlice';
import { SaveResponse } from '../../firebase';
import FormikControl from './FormikControl';

const SignUpForm = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const response = useAppSelector((state: RootState) => state.response);
  const navigate = useNavigate();
  //emailjs detail
  const emailServiceID = process.env.REACT_APP_EMAILJS_SERVICE_KEY;
  const sendEmailUser = process.env.REACT_APP_APL_TO_USER;
  const sendEmailAPL = process.env.REACT_APP_USER_TO_APL;
  const emailPublicKey = process.env.REACT_APP_EMAILJS_KEY;
  const initialValues = {
    email: '',
    firstName: '',
    surname: '',
    phone: '',
    address: '',
    town: '',
    state: '',
    postcode: '',
  };
  const validationSchema = Yup.object({
    email: Yup.string().required('Required'),
    firstName: Yup.string().required('Required'),
    surname: Yup.string().required('Required'),
    phone: Yup.string()
      .required()
      .phone('IN', false, "Please add '+' w/ country code"),
    address: Yup.string().required('Required'),
    town: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    postcode: Yup.string().required('Required'),
  });
  const onSubmit = async (values: any) => {
    setLoading(true);
    const { dateOfInjury, ...newData } = response;
    const newResponse = {
      ...values,
      ...newData,
      dateOfInjury: dateOfInjury?.toDateString(),
    };
    if (emailServiceID && sendEmailAPL && sendEmailUser && emailPublicKey) {
      await emailjs.send(
        emailServiceID,
        sendEmailUser,
        newResponse,
        emailPublicKey
      );
      await emailjs.send(
        emailServiceID,
        sendEmailAPL,
        newResponse,
        emailPublicKey
      );
    }
    await SaveResponse(newResponse);
    setLoading(false);
    dispatch(open('home'));
    navigate('/thankyou');
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(formik: any) => {
        return (
          <Form className=' flex flex-col items-center space-y-5 mt-4'>
            {!loading && (
              <>
                <FormikControl
                  control='input'
                  type='text'
                  label='First Name'
                  name='firstName'
                  placeholder='Enter your first name'
                />
                <FormikControl
                  control='input'
                  type='text'
                  label='Surname'
                  name='surname'
                  placeholder='Enter your surname'
                />
                <FormikControl
                  control='input'
                  type='text'
                  label='Address'
                  name='address'
                  placeholder='Enter your address'
                />
                <FormikControl
                  control='input'
                  type='text'
                  label=''
                  name='town'
                  placeholder='Enter your town or city'
                />
                <div className='self-start sm:self-end flex justify-start items-end space-x-2'>
                  <FormikControl
                    control='input'
                    type='text'
                    label='State'
                    name='state'
                    placeholder='State'
                  />
                  <FormikControl
                    control='input'
                    type='text'
                    label='Postcode'
                    name='postcode'
                    placeholder='Postcode'
                  />
                </div>
                <FormikControl
                  control='input'
                  type='tel'
                  label='Mobile Phone Number'
                  name='phone'
                  placeholder='Enter your mobile phone number'
                />
                <FormikControl
                  control='input'
                  type='email'
                  label='Email Address'
                  name='email'
                  placeholder='Enter your email address'
                />
              </>
            )}

            {!loading ? (
              <button type='submit'>
                <motion.div
                  role={'presentation'}
                  whileHover={{
                    scale: 1.1,
                    textShadow: '0px 0px 8px rgb(255,255,255)',
                    boxShadow: '0px 0px 8px rgb(255,255,255)',
                    borderRadius: '9999px',
                  }}
                  className='flex flex-row justify-center font-se items-center p-1  w-[130px] bg-[#58061E] opacity-100 text-white text-lg tracking-normal rounded-full cursor-pointer'
                >
                  Submit
                </motion.div>
              </button>
            ) : (
              <button type='submit' disabled>
                <motion.div
                  role={'presentation'}
                  whileHover={{
                    textShadow: '0px 0px 8px rgb(255,255,255)',
                    boxShadow: '0px 0px 8px rgb(255,255,255)',
                    borderRadius: '9999px',
                  }}
                  className='flex flex-row justify-center font-se items-center p-1  w-[130px] bg-[#58061E] opacity-80 text-white text-lg tracking-normal rounded-full cursor-wait'
                >
                  <svg
                    className='animate-spin h-5 w-5 mr-3'
                    viewBox='0 0 24 24'
                  >
                    <FontAwesomeIcon icon={faSpinner} />
                  </svg>
                  Loading...
                </motion.div>
              </button>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default SignUpForm;
