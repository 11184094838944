import Scrollbars from "react-custom-scrollbars";
import {useSelector} from "react-redux";
import {ModalSelector} from "../../features/responseSlice/reponseSelector";
import Modal from "../Modal/Modal";
import PageContainer from "../PageContainer";
import SignUpForm from "./SignUpForm";

const ContactDetails = () => {
    const isOpen = useSelector(ModalSelector);
    return (
        <PageContainer title='Contact Details'>
            {!isOpen && (
                <>
                    <div className='lg:w-[800px] text-md flex flex-col justify-center items-center sm:text-2xl leading-[36px]  text-[#5A5A5A]'>
                        <span className='pb-2 md:mx-0 text-center'>
                            To enable us to assist you further, please enter
                            your contact details below.
                        </span>
                    </div>
                    <div className='border border-[#B08662] flex justify-center sm:mx-8 py-2 lg:mx-0'>
                        <SignUpForm />
                    </div>
                </>
            )}

            <div>
                <Modal isOpen={isOpen}>
                    <Scrollbars
                        autoHide={false}
                        className='overscroll-x-none w-[957px] h-[350px] text-2xl mx-[13px] my-0 overflow-hidden mt-[31px] text-[#707070]'>
                        <div className='p-2 font-bold'>
                            Workplace Personal Injury
                        </div>
                        <div className='leading-[29px] flex flex-col space-y-10 p-2'>
                            <span>
                                A personal injury specialist will be in contact
                                with you shortly to arrange a time to discuss
                                your injury.
                            </span>
                        </div>
                    </Scrollbars>
                </Modal>
            </div>
        </PageContainer>
    );
};

export default ContactDetails;
