import { init, track } from 'fbq';
import { useEffect } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';
import { ModalSelector } from '../features/responseSlice/reponseSelector';

import Modal from './Modal/Modal';

export default function Thankyou() {
  useEffect(() => {
    init('723342295671008');
    track('Lead');
  }, []);

  const visible = useSelector(ModalSelector);
  return (
    <div>
      <Modal isOpen={visible}>
        <Scrollbars
          autoHide={false}
          className='overscroll-x-none w-[957px] h-[350px] text-2xl mx-[13px] my-0 overflow-hidden mt-[31px] text-[#707070]'
        >
          <div className='p-2 font-bold'>Workplace Personal Injury</div>
          <div className='leading-[29px] flex flex-col space-y-10 p-2'>
            <span>
              A personal injury specialist will be in contact with you shortly
              to arrange a time to discuss your injury.
            </span>
          </div>
        </Scrollbars>
      </Modal>
    </div>
  );
}
