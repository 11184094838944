import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {motion} from "framer-motion";
import {useRef, useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../app/hooks";
import {setDateOfInjury} from "../../features/responseSlice/responseSlice";
import NexPageButton from "../NexPageButton";
import PageContainer from "../PageContainer";
import "./Q1.css";

const Q2 = () => {
    const [date, setDate] = useState<Date | null>();
    const dateRef = useRef<DatePicker>(null);
    const dispatch = useAppDispatch();
    const [placeHolder, setPlaceHolder] = useState(
        "Select your date of injury"
    );
    const navigate = useNavigate();
    const handleClick = () => {
        dateRef.current?.setFocus();
    };

    const handleFocus = () => {
        setPlaceHolder("You may type here...");
    };

    const handleDateChange = (date: Date) => {
        setDate(date);
    };

    const handleNextPage = () => {
        const value = dateRef.current?.props.selected;

        if (!(value === null || value === undefined)) {
            dispatch(setDateOfInjury(value));
            navigate("/q3");
        } else {
            alert("Please select date of your injury");
        }
    };
    return (
        <PageContainer title='Date of Injury' pageNumber='2'>
            <div className='flex  w-[884px] justify-between flex-col space-y-4 items-center lg:flex-row'>
                <div className='flex flex-row text-center'>
                    <span className='text-[#5A5A5A] text-[20px] sm:text-[30px]'>
                        What was the date of your injury?
                    </span>
                </div>
                <motion.div className='w-[308px] relative self-center flex flex-row border border-[#DED1C5] cursor-pointer'>
                    <DatePicker
                        onChange={handleDateChange}
                        selected={date}
                        className='h-[45px] outline-none w-full select-none px-2  text-[20px] text-[#A09FA1] leading-[29px] cursor-pointer'
                        placeholderText={placeHolder}
                        dateFormat={["do MMMM yyyy", "dd/MM/yyyy"]}
                        ref={dateRef}
                        onFocus={handleFocus}
                        showYearDropdown
                        showMonthDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={50}
                    />
                    <div
                        className='absolute right-0 w-[45px] h-full bg-[#B08662] flex justify-center items-center'
                        onClick={handleClick}>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                            className='text-white p-0'
                        />
                    </div>
                </motion.div>
            </div>
            <div
                className='flex justify-center items-center p-20'
                onClick={handleNextPage}>
                <NexPageButton />
            </div>
        </PageContainer>
    );
};

export default Q2;
