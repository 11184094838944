import { init, track } from 'fbq';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PageContainer from '../PageContainer';

const WelcomeScreen = () => {
  useEffect(() => {
    init('723342295671008');
    track('PageView');
  }, []);
  return (
    <PageContainer>
      <section className='text text-center p-4 flex flex-col space-y-2 text-xl leading-normal tracking-[3.5px] sm:tracking-[5.5px] sm:text-4xl sm:space-y-8 lg:text-5xl lg:px-36  xl:px-96'>
        <p>Welcome to the Personal Injury Questionnaire.</p>
        <p>
          Answering a few simple questions will help you understand if you are
          eligible for free legal advice from an accredited specialist personal
          injury lawyer.
        </p>
      </section>
      <motion.div
        className='flex justify-center items-center pt-20'
        whileHover={{
          scale: 1.1,
          textShadow: '0px 0px 8px rgb(255,255,255)',
          boxShadow: '0px 0px 8px rgb(255,255,255)',
          borderRadius: '9999px',
        }}
      >
        <Link
          to='/q1'
          className='flex flex-row justify-center  items-center h-16 w-64 bg-[#58061E] opacity-100 text-white text-2xl tracking-normal rounded-full'
        >
          Continue
        </Link>
      </motion.div>
    </PageContainer>
  );
};

export default WelcomeScreen;
