import {faQuestion} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {motion} from "framer-motion";
import {useRef, useState} from "react";
import Scrollbars from "react-custom-scrollbars";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../app/hooks";
import {ModalSelector} from "../../features/responseSlice/reponseSelector";
import {open, setWasAdvice} from "../../features/responseSlice/responseSlice";
import Modal from "../Modal/Modal";
import NexPageButton from "../NexPageButton";
import PageContainer from "../PageContainer";

const Q5 = () => {
    const navigate = useNavigate();
    const yesRef = useRef<HTMLInputElement>(null);

    const noRef = useRef<HTMLInputElement>(null);
    const isOpen = useSelector(ModalSelector);
    const [isYes, setIsYes] = useState(isOpen);
    const dispatch = useAppDispatch();
    const handleNextPage = () => {
        const yes = yesRef.current?.checked;
        const no = noRef.current?.checked;
        if (!(yes || no)) alert("Please select either Yes or No");
        else {
            if (no) {
                setIsYes(false);
                dispatch(setWasAdvice(false));
                navigate("/summary");
            } else {
                dispatch(setWasAdvice(true));
                setIsYes(true);
                dispatch(open("home"));
            }
        }
    };
    return (
        <PageContainer title='Legal Advice' pageNumber='5'>
            <div className='flex space-x-8 items-center flex-col space-y-10 xl:flex-row xl:space-y-0'>
                <div className='p-2 lg:flex lg:flex-row text-center'>
                    <span className='text-[#5A5A5A] w-[900] text-xl sm:text-2xl lg:text-3xl lg:text-justify px-1'>
                        Have you previously received free legal advice relating
                        to this injury?
                    </span>
                    <motion.div
                        whileHover={{
                            scale: 1.1,
                            textShadow: "0px 0px 2px #B08662",
                            boxShadow: "0px 0px 5px #B08662",
                            borderRadius: "9999px",
                        }}
                        className='inline-flex align-top 2xl:flex w-6 h-6 justify-center items-center border border-[#B08662] rounded-full cursor-pointer'
                        onClick={() => {
                            dispatch(open(""));
                            setIsYes(false);
                        }}>
                        <FontAwesomeIcon
                            icon={faQuestion}
                            className='text-[#B08662] '
                        />
                    </motion.div>
                </div>
                <motion.div className='flex flex-col justify-end space-y-[9px] items-center  text-2xl text-[#5A5A5A]'>
                    <div className='flex items-center space-x-[13px]'>
                        <label htmlFor='yes'>Yes</label>
                        <input
                            type='radio'
                            id='yes'
                            name='eng_language'
                            value={1}
                            className='w-[30px] h-[30px] accent-[#B88E27]'
                            ref={yesRef}
                        />
                    </div>
                    <div className='flex items-center space-x-[18px]'>
                        <label htmlFor='no' className='pl-1'>
                            No
                        </label>
                        <input
                            type='radio'
                            id='no'
                            name='eng_language'
                            value={0}
                            className='w-[30px] h-[30px] accent-[#B88E27]'
                            ref={noRef}
                        />
                    </div>
                </motion.div>
            </div>
            <div
                className='flex justify-center items-center pt-10'
                onClick={handleNextPage}>
                <NexPageButton />
            </div>
            <div>
                <Modal isOpen={isOpen}>
                    <Scrollbars
                        autoHide={false}
                        className='w-[957px] h-[350px] text-2xl mx-[13px] my-0  overflow-hidden mt-[31px] text-[#707070]'>
                        <div className='p-4 font-extrabold'>
                            Workplace Personal Injury
                        </div>
                        <div className='leading-[29px] flex flex-col space-y-10 p-4'>
                            <span>
                                Under the NSW Government’s Independent Legal
                                Assistance and Review Service (‘ILARS’), anyone
                                injured in a NSW workplace is entitled to access
                                free legal advice, assistance and representation
                                with respect to their rights and entitlements
                                under workers compensation legislation.
                            </span>
                            {isYes && (
                                <span>
                                    As you have said that you have already
                                    received free legal advice in relation to
                                    your injury, you will not be entitled to a
                                    free consultation with us. If you have
                                    further questions, we recommend that you
                                    contact the personal injury lawyer who
                                    initially gave you the free advice.
                                </span>
                            )}
                        </div>
                    </Scrollbars>
                </Modal>
            </div>
        </PageContainer>
    );
};

export default Q5;
