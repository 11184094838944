import {motion} from "framer-motion";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../app/hooks";
import {RootState} from "../../app/store";
import {close} from "../../features/responseSlice/responseSlice";
const Modal = ({
    isOpen,
    children,
    disabledCloseBtn,
    closeOnMouseLeave,
}: {
    isOpen: boolean;
    children: React.ReactNode;
    disabledCloseBtn?: boolean;
    closeOnMouseLeave?: boolean;
}) => {
    const dispatch = useAppDispatch();
    const goto = useSelector((state: RootState) => state.response.selected);
    const navigate = useNavigate();
    const [button, setButton] = useState("Close");

    useEffect(() => {
        if (goto === "contact") setButton("Continue");

        return () => {
            setButton("Close");
        };
    }, [goto]);

    if (!isOpen) return null;

    const handleClick = () => {
        if (goto === "home") navigate("/");
        if (goto === "contact") {
            navigate("/contactdetails");
        }
        if (goto === "apl" || goto === "apl_q4") navigate("/apl");

        dispatch(close());
    };

    const _handleOnMouseLeave = () => {};

    const handleClickChangeAnswer = () => {
        dispatch(close());
    };

    return (
        <motion.div
            role='presentation'
            className='fixed top-14 left-0  h-screen w-full flex justify-center items-center  z-[1] '
            onClick={_handleOnMouseLeave}
            onLoadedData={_handleOnMouseLeave}>
            <motion.div
                initial={{
                    y: 0,
                    x: 0,
                    opacity: 0.5,
                }}
                animate={{
                    y: 0,
                    x: 0,
                    opacity: 1,
                }}
                transition={{
                    duration: 0.5,
                }}
                role='presentation'
                className='flex flex-col relative  items-center bg-white rounded-sm w-[500px] h-[410px] sm:w-[600px] md:[760px] lg:w-[1008px] lg:h-[458px] z-[1003] drop-shadow-lg'
                onClick={(event) => event.stopPropagation()}>
                {children}
                {!disabledCloseBtn && (
                    <div className='flex flex-row space-x-2 self-end px-2 pb-1'>
                        {goto === "apl" && (
                            <div
                                className=' m-2 mx-3 flex flex-row justify-center items-center h-[51px] w-[145px] bg-[#58061E] opacity-100 text-white text-lg tracking-normal rounded-full cursor-pointer hover:bg-[#7a082a] '
                                onClick={handleClickChangeAnswer}>
                                Change
                            </div>
                        )}
                        <div
                            className=' m-2 mx-3 flex flex-row justify-center items-center h-[51px] w-[145px] bg-[#58061E] opacity-100 text-white text-lg tracking-normal rounded-full cursor-pointer hover:bg-[#7a082a] '
                            onClick={handleClick}>
                            {button}
                        </div>
                    </div>
                )}
            </motion.div>
        </motion.div>
    );
};

export default Modal;
