import {useRef} from "react";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../app/hooks";
import {setDescription} from "../../features/responseSlice/responseSlice";
import NexPageButton from "../NexPageButton";
import PageContainer from "../PageContainer";

const Q3 = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const ref = useRef<HTMLTextAreaElement>(null);
    const handleNextPage = () => {
        const value = ref.current?.value;
        if (!value) alert("Please describe your injury in the field below.");
        else {
            dispatch(setDescription(value));
            navigate("/q4");
        }
    };
    return (
        <PageContainer title='Describe your Injury' pageNumber='3'>
            <div className='flex space-x-8  flex-col  space-y-10 xl:flex-row xl:space-y-0'>
                <div className='p-2  text-center'>
                    <span className='text-[#5A5A5A]  text-3xl text-center'>
                        Describe your injury
                    </span>
                </div>
                <div className='flex justify-center  items-center sm:flex-col sm:justify-end space-y-[9px]   sm:text-2xl text-[#5A5A5A]'>
                    <textarea
                        className='border border-[#DED1C5] outline-none w-[250px] h-36 sm:w-[500px] p-3 text-lg sm:ml-5 text-[#A09FA1] sm:h-52 whitespace-pre-wrap'
                        placeholder='Enter a description of your injury including how and where it occurred'
                        ref={ref}
                    />
                </div>
            </div>
            <div className='flex justify-center pt-20' onClick={handleNextPage}>
                <NexPageButton />
            </div>
        </PageContainer>
    );
};

export default Q3;
