import {combineReducers} from "@reduxjs/toolkit";
import responseReducer from "./responseSlice/responseSlice";

const reducers = {
    response: responseReducer,
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
