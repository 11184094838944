import {motion} from "framer-motion";

const NexPageButton = () => {
    return (
        <motion.div
            whileHover={{
                scale: 1.1,
                textShadow: "0px 0px 8px rgb(88,6,30)",
                boxShadow: "0px 0px 8px rgb(88,6,30)",
                borderRadius: "6px",
            }}
            className='relative w-32 h-32 flex justify-center items-center sm:mt-20 '>
            <img
                src='/images/arrowhex.png'
                alt='hexagon'
                className='absolute -z-10  w-30 h-30'
            />
        </motion.div>
    );
};

export default NexPageButton;
