import {motion} from "framer-motion";
import {useEffect, useRef} from "react";
import Scrollbars from "react-custom-scrollbars";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../app/hooks";
import {ModalSelector} from "../../features/responseSlice/reponseSelector";
import {
    close,
    open,
    setInjury,
} from "../../features/responseSlice/responseSlice";
import Modal from "../Modal/Modal";
import NexPageButton from "../NexPageButton";
import PageContainer from "../PageContainer";

const Q1 = () => {
    const navigate = useNavigate();
    const yesRef = useRef<HTMLInputElement>(null);
    const noRef = useRef<HTMLInputElement>(null);
    const isOpen = useSelector(ModalSelector);
    const dispatch = useAppDispatch();
    const handleNextPage = () => {
        const yes = yesRef.current?.checked;
        const no = noRef.current?.checked;
        if (!(yes || no)) alert("Please select either Yes or No");
        else {
            if (no) {
                dispatch(setInjury(false));
                dispatch(open("apl"));
            } else {
                dispatch(setInjury(true));
                navigate("/q2");
            }
        }
    };

    useEffect(() => {
        dispatch(close());
    }, [dispatch]);

    return (
        <PageContainer title='Injury' pageNumber='1'>
            <div className='flex space-x-8 items-center flex-col space-y-10 xl:flex-row xl:space-y-0'>
                <div className='p-2 lg:flex lg:flex-row text-center'>
                    <span className='text-[#5A5A5A] text-3xl '>
                        Were you injured at work or while doing your job?
                    </span>
                </div>
                <motion.div className='flex flex-col justify-end space-y-[9px] items-center  text-2xl text-[#5A5A5A]'>
                    <div className='flex items-center space-x-[13px]'>
                        <label htmlFor='yes'>Yes</label>
                        <input
                            type='radio'
                            id='yes'
                            name='eng_language'
                            value={1}
                            className='w-[30px] h-[30px] accent-[#B88E27]'
                            ref={yesRef}
                        />
                    </div>
                    <div className='flex items-center space-x-[18px]'>
                        <label htmlFor='no' className='pl-1'>
                            No
                        </label>
                        <input
                            type='radio'
                            id='no'
                            name='eng_language'
                            value={0}
                            className='w-[30px] h-[30px] accent-[#B88E27]'
                            ref={noRef}
                        />
                    </div>
                </motion.div>
            </div>
            <div
                className='flex justify-center items-center pt-10 sm:pt-22'
                onClick={handleNextPage}>
                <NexPageButton />
            </div>
            <div>
                <Modal isOpen={isOpen}>
                    <Scrollbars
                        autoHide={false}
                        className='w-[957px] h-[350px] text-2xl mx-[13px] my-0  overflow-hidden mt-[31px] text-[#707070]'>
                        <div className='p-4 font-extrabold'>
                            Workplace Personal Injury
                        </div>
                        <div className='leading-[29px] flex flex-col space-y-10 p-4'>
                            <span>
                                As you have not suffered an injury at your
                                workplace or while doing your job, we can’t
                                offer any further assistance. If you have been
                                injured at work, and have clicked “no” in error,
                                please press the “Change” button and select
                                “yes.”
                            </span>
                        </div>
                    </Scrollbars>
                </Modal>
            </div>
        </PageContainer>
    );
};

export default Q1;
