import {initializeApp} from "firebase/app";
import {addDoc, collection, initializeFirestore} from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCsfupzYkZKnREErp2yovosQRtdjCeZOno",
    authDomain: "apl-personal-injury.firebaseapp.com",
    projectId: "apl-personal-injury",
    storageBucket: "apl-personal-injury.appspot.com",
    messagingSenderId: "93770779081",
    appId: "1:93770779081:web:7cf6f567ae655583740018",
    measurementId: "G-4MYXVM8C4L",
};

const app = initializeApp(firebaseConfig);
export const db = initializeFirestore(app, {ignoreUndefinedProperties: true});

export const SaveResponse = async (values: any) => {
    await addDoc(collection(db, "response"), {
        ...values,
    }).catch((err) => {
        alert(err);
        return false;
    });
};

export default app;
