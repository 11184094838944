import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type InitialStateType = {
    isOpen: boolean;
    selected: string;
    isInjury: boolean;
    dateOfInjury: Date | null;
    description: string | null;
    needAdvice: boolean;
    wasAdvice: boolean;
    loading: false;
};

const initialState: InitialStateType = {
    isOpen: false,
    selected: "",
    isInjury: false,
    dateOfInjury: null,
    needAdvice: false,
    wasAdvice: false,
    description: null,
    loading: false,
};

const ResponseSlice = createSlice({
    name: "response",
    initialState,
    reducers: {
        open: (state: InitialStateType, {payload}: PayloadAction<string>) => {
            state.isOpen = true;
            state.selected = payload;
        },
        close: (state: InitialStateType) => {
            state.isOpen = false;
        },
        setInjury: (
            state: InitialStateType,
            {payload}: PayloadAction<boolean>
        ) => {
            state.isInjury = payload;
        },
        setDateOfInjury: (
            state: InitialStateType,
            {payload}: PayloadAction<Date>
        ) => {
            state.dateOfInjury = payload;
        },
        setDescription: (
            state: InitialStateType,
            {payload}: PayloadAction<string>
        ) => {
            state.description = payload;
        },
        setNeedAdvice: (
            state: InitialStateType,
            {payload}: PayloadAction<boolean>
        ) => {
            state.needAdvice = payload;
        },
        setWasAdvice: (
            state: InitialStateType,
            {payload}: PayloadAction<boolean>
        ) => {
            state.wasAdvice = payload;
        },
    },
});

export const {
    open,
    close,
    setDateOfInjury,
    setDescription,
    setInjury,
    setWasAdvice,
    setNeedAdvice,
} = ResponseSlice.actions;
export default ResponseSlice.reducer;
