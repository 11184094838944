import React from "react";

import Input from "./Input";

interface FormikControlProps {
    control: string;
    name: string;
    type: string;
    label?: string;
    placeholder?: string;
    value?: string[] | string | number;
    disabled?: boolean;
    children?: React.ReactNode;
}

function FormikControl(props: FormikControlProps) {
    const {control, children, ...rest} = props;
    switch (control) {
        case "input":
            return <Input {...rest} />;

        default:
            return null;
    }
}

export default FormikControl;
