import {ErrorMessage, Field, FieldAttributes} from "formik";
import {useEffect, useState} from "react";
import TextError from "./TextError";

function Input(props: FieldAttributes<any>) {
    const {label, placeholder, name, value, ...rest} = props;
    const [width, setWidth] = useState("w-[350px]");
    const [widthMobile, setWidthMobile] = useState("w-[230px]");

    useEffect(() => {
        if (label === "State" || label === "Postcode") {
            setWidth("w-[123px]");
            setWidthMobile("w-[100px]");
        }
    }, [width, label]);

    return (
        <div className='sm:w-full  text-[#5A5A5A] px-2 sm:px-0'>
            <div className='flex flex-col sm:flex-row justify-end items-center'>
                <label htmlFor={name} className='self-start text-sm sm:text-lg'>
                    {label}
                </label>
                <Field
                    id={name}
                    name={name}
                    {...rest}
                    className={`border border-[#DED1C5] text-sm outline-none ${widthMobile} sm:${width} sm:py-1 pl-4 sm:text-md sm:ml-5 text-[#5A5A5A] `}
                    placeholder={placeholder}
                    value={value}
                />
            </div>

            <ErrorMessage component={TextError} name={name} />
        </div>
    );
}

export default Input;
