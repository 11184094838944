import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence } from 'framer-motion';
import { Route, Routes } from 'react-router-dom';
import ContactDetails from './components/ContactDetails/ContactDetails';
import Q1 from './components/pages/Q1';
import Q2 from './components/pages/Q2';
import Q3 from './components/pages/Q3';
import Q4 from './components/pages/Q4';
import Q5 from './components/pages/Q5';
import Summary from './components/pages/Summary';
import WelcomeScreen from './components/pages/WelcomeScreen';
import Thankyou from './components/Thankyou';

const Navigate = () => {
  window.location.href = 'https://australianpresencelegal.com/';
  return (
    <div className='flex flex-col justify-center items-center space-y-2 translate-y-1/2'>
      <svg className='animate-spin h-20 w-20 ' viewBox='0 0 24 24'>
        <FontAwesomeIcon icon={faSpinner} className='text-[#58061E]' />
      </svg>
      <h1 className='text text-center'>
        Connecting to https://australianpresencelegal.com/
      </h1>
    </div>
  );
};

function App() {
  return (
    <AnimatePresence>
      <Routes>
        <Route path='/' element={<WelcomeScreen />} />
        <Route path='/q1' element={<Q1 />} />
        <Route path='/q2' element={<Q2 />} />
        <Route path='/q3' element={<Q3 />} />
        <Route path='/q4' element={<Q4 />} />
        <Route path='/q5' element={<Q5 />} />
        <Route path='/contactdetails' element={<ContactDetails />} />
        <Route path='/summary' element={<Summary />} />
        <Route path='/apl' element={<Navigate />} />
        <Route path='/thankyou' element={<Thankyou />} />
      </Routes>
    </AnimatePresence>
  );
}

export default App;
