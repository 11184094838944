import classNames from "classnames";
import {motion} from "framer-motion";
import APLImage from "../images/APL-black-600px.png";

interface PageContainerProps {
    children: React.ReactNode;
    title?: string;
    pageNumber?: string;
}

const PageContainer = ({children, pageNumber, title}: PageContainerProps) => {
    return (
        <motion.div
            initial={{
                y: 500,
                x: 0,
                opacity: 0,
            }}
            animate={{
                y: 0,
                x: 0,
                opacity: 1,
            }}
            transition={{
                type: "tween",
                ease: "anticipate",
                duration: 1,
            }}
            exit={{
                y: 0,
                y1: 1000,
                opacity: 0,
            }}
            className={classNames("flex flex-col  items-center", {
                "sm:space-y-14": title !== "Contact Details" || title,
                "sm:space-y-5 ":
                    title === "Contact Details" || title === "Summary",
            })}>
            <div className='flex justify-center items-center'>
                <img src={APLImage} alt='APL_Logo' className='pt-16 p-4' />
            </div>
            {title && (
                <div className='text-4xl text-[#58061E]  leading-[68px] text-center sm:text-6xl'>
                    {title}
                </div>
            )}
            {pageNumber && (
                <div className='text-5xl leading-[109px] font-bold tracking-normal sm:text-7xl'>
                    <span className='text-[#DED1C5]'>0</span>
                    <span className='text-[#AE8625]'>{`${pageNumber}.`}</span>
                </div>
            )}
            <div>{children}</div>
        </motion.div>

        //image
        //title
        //number
        //body
    );
};

export default PageContainer;
