import {useRef} from "react";
import Scrollbars from "react-custom-scrollbars";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import {ModalSelector} from "../../features/responseSlice/reponseSelector";
import {open} from "../../features/responseSlice/responseSlice";
import Modal from "../Modal/Modal";
import NexPageButton from "../NexPageButton";
import PageContainer from "../PageContainer";

const Summary = () => {
    const response = useAppSelector((state: RootState) => state.response);
    const confirmationRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();
    const isOpen = useAppSelector(ModalSelector);
    const handleConfirmation = () => {
        const confirmed = confirmationRef?.current?.checked;

        if (confirmed) {
            dispatch(open("contact"));
        } else {
            alert("Please confirm the details");
        }
    };
    return (
        <PageContainer title='Summary'>
            {!isOpen && (
                <>
                    <div className='flex flex-col justify-center items-start leading-none space-y-6 text-[#5A5A5A] ml-4 md:text-xl xl:w-[1303px] xl:h-[360px] xl:text-[20px] xl:leading-[36px]'>
                        <span className=' font-bold'>Summary of responses</span>
                        <span>
                            Below is a summary of the answers you have provided:
                        </span>
                        <div className='flex flex-col'>
                            <span>
                                - You were injured in the workplace or while
                                performing your job on{" "}
                                <span className='underline'>
                                    {response.dateOfInjury?.toDateString()}
                                </span>
                            </span>

                            <span>
                                - You are seeking free legal advice from an
                                accredited specialist in personal injury law
                            </span>
                            <span>
                                - You have not previously received free legal
                                advice relating to this injury
                            </span>
                            <span>
                                - You describe your injury as:
                                <span className='block pl-2 capitalize-first'>
                                    {response.description}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className='flex leading-none pt-4 items-center space-x-4 mx-4 lg:mx-0 sm:text-xl xl:text-[20px] text-[#707070]'>
                        <label htmlFor='confirmation'>
                            Select this box to confirm that the above
                            information is true and accurate before proceeding
                        </label>
                        <input
                            type={"checkbox"}
                            id='confirmation'
                            className='w-[30px] h-[30px] accent-[#B88E27]'
                            ref={confirmationRef}
                        />
                    </div>
                    <div
                        className='flex justify-center pt-10'
                        onClick={handleConfirmation}>
                        <NexPageButton />
                    </div>{" "}
                </>
            )}

            <div>
                <Modal isOpen={isOpen}>
                    <Scrollbars
                        autoHide={false}
                        className='overscroll-x-none w-[957px] h-[350px] text-2xl mx-[13px] my-0 overflow-hidden mt-[31px] text-[#707070]'>
                        <div className='p-5 font-bold'>
                            Workplace Personal Injury
                        </div>
                        <div className='leading-[29px] flex flex-col space-y-10 p-5 text-justify'>
                            <span>
                                Congratulations! Based on your answers, you are
                                entitled to receive free legal advice about your
                                workplace injury. Please provide us with your
                                details so that we can arrange an appointment
                                for your consultation.
                            </span>
                        </div>
                    </Scrollbars>
                </Modal>
            </div>
        </PageContainer>
    );
};

export default Summary;
